import React from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Hero from "../components/hero";

export default ({ data }) => (
  <Layout>
    <SEO
      title="Services"
      keywords={[
        "electrical",
        "Philippines",
        "design",
        "build",
        "system",
        "supply",
        "troubleshoot",
        "repair",
        "equipments",
        "test",
        "manage",
        "bms"
      ]}
    />
    <div className="gradient-half-blue-v1">
      <Hero
        desktopWide={data.bgDesktopWide.childImageSharp.fluid.src}
        desktop={data.bgDesktop.childImageSharp.fluid.src}
        tablet={data.bgTablet.childImageSharp.fluid.src}
        mobile={data.bgMobile.childImageSharp.fluid.src}
        mobileXS={data.bgMobileXS.childImageSharp.fluid.src}
      >
        <h1 className="text-white">Services</h1>
        <p className="text-white lead"></p>
        <a
          className="js-go-to u-icon u-icon--light u-icon--md rounded-circle"
          href="#Services"
        >
          <span className="fa fa-angle-down u-icon__inner text-purple font-size-18"></span>
        </a>
      </Hero>
    </div>

    <div id="Services" className="container space-1 space-2--lg">
      <div className="w-md-80 w-lg-60 text-center mx-md-auto mb-4">
        <span className="u-label u-label--sm u-label--success mb-3">
          Committed
        </span>
        <h2 className="h3">Let Us Help You</h2>
      </div>
    </div>

    <div className="container space-2-bottom space-3-bottom--lg">
      <div className="row space-1-bottom space-2-bottom--lg">
        <div className="col-md-6 mb-5 mx-2 mx-md-0">
          <h3>Engineering Consultancy</h3>
          <p>
            We provide professional and expert advice in the field of electrical
            engineering and control systems. We have a group of experienced and
            licensed engineers that can provide a technical advice or define a
            solution and recommend the best course of action that the client
            could take. A good engineering consultant like us can help you save
            time effort and money.
          </p>
          <h3>System Design and Upgrading</h3>
          <p>
            We plan and create a design using a methodological approach, from
            drawing schematic diagrams, electrical layouts, cable and bus duct
            routings up to project execution. Everything is carefully studied
            and considered to achieve the best result.{" "}
          </p>
          <h3>Preventive Maintenance</h3>
          <p>
            Periodical preventive maintenance is very important and should be
            performed in a regular basis to lessen the likelihood of the
            equipment failing. It is also important in prolonging the life of
            the equipment.
          </p>
          <h3>Application Assistance</h3>
          <p>
            We assist in the configuration and setting up of our equipments and
            controllers to ensure that it will operate smoothly. It will also
            give additional knowledge to the client’s personnel to make them
            more confident in the equipment’s operation.
          </p>
        </div>
        <div className="col-md-6 mb-5 mx-2 mx-md-0">
          <Img
            className="img-fluid mx-auto d-block shadow-lg p-3 mb-5 bg-white rounded"
            fluid={data.vision.childImageSharp.fluid}
            alt="Distribution Boards"
            title="Distribution Boards"
          />
          <h3>We also do</h3>
          <ul>
            <li>Technical Services and Support</li>
            <li>Project Management and Coordination</li>
            <li>
              Rehabilitation and Troubleshooting of Generators and Synchronizing
              Panels
            </li>
          </ul>
        </div>
      </div>
    </div>
  </Layout>
);

export const query = graphql`
  query {
    bgDesktopWide: file(relativePath: { eq: "static/services.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 90, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    bgDesktop: file(relativePath: { eq: "static/services.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 90, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    bgTablet: file(relativePath: { eq: "static/services.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 992, quality: 90, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    bgMobile: file(relativePath: { eq: "static/services.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 768, quality: 90, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    bgMobileXS: file(relativePath: { eq: "static/services.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 576, quality: 90, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    vision: file(relativePath: { eq: "vision.jpeg" }) {
      childImageSharp {
        fluid(maxHeight: 700, quality: 90, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`;
